import { Navigate } from '@remix-run/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { $path } from 'remix-routes';

import { useOnboardingAnalytics } from '../analytics/onboarding';
import { Loading } from '../components/Loading';
import { OnboardingPageLayout } from '../components/Onboarding/OnboardingLayout';
import {
  useMyOrganizer,
  useUpdateMyOrganizer,
} from '../components/Organization';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { getQueryParam } from '../hooks/useQueryParam';
import { apiService } from '../services/api-service';
import { type Organizer } from '../types';
import { err2s } from '../utils/common';

type FormData = {
  firstName: string;
  lastName: string;
};

function Container(props: {
  organizer: Organizer;
  onChange: (updated: Organizer) => void;
}) {
  const { organizer, onChange } = props;

  const analytics = useOnboardingAnalytics();

  const { register, handleSubmit, formState, setFocus } = useForm<FormData>({
    defaultValues: organizer.lastName
      ? {
          firstName: organizer.firstName,
          lastName: organizer.lastName,
        }
      : {
          firstName: '',
          lastName: '',
        },
  });

  const {
    call: onSubmit,
    state: { error },
  } = useLiveAsyncCall(
    handleSubmit(async (data: FormData) => {
      analytics.trackOnboardingNameSaved();

      const resp = await apiService.organization.updateOrganizer(
        organizer.orgId,
        organizer.uid,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      );
      const code = getQueryParam('code');
      if (code) {
        apiService.auth.deleteCode(code);
      }

      onChange(resp.data.organizer);
    })
  );

  useEffect(() => {
    setFocus('firstName');
  }, [setFocus]);

  return (
    <OnboardingPageLayout progress={10}>
      <form
        className='        
          max-w-140 lg:bg-modal text-white 
          rounded lg:rounded-2.5xl 
          px-5 lg:px-17 py-0 lg:py-10
          flex flex-col items-center
        '
        onSubmit={onSubmit}
      >
        <h2 className='text-2xl font-medium text-tertiary'>
          Just a few more details
        </h2>

        <div className='mt-7.5 w-full'>
          <p className='font-bold'>What’s your name?</p>
          <div className='mt-2.5 flex justify-center items-center gap-5'>
            <label>
              <input
                className={`w-full h-12.5 ${
                  formState.errors.firstName ? 'field-error' : 'field'
                } mb-0`}
                placeholder='First Name'
                {...register('firstName', {
                  required: true,
                  maxLength: 50,
                })}
                maxLength={50}
              />
            </label>
            <label>
              <input
                className={`w-full h-12.5 ${
                  formState.errors.lastName ? 'field-error' : 'field'
                } mb-0`}
                placeholder='Last Name'
                {...register('lastName', {
                  required: true,
                  maxLength: 50,
                })}
                maxLength={50}
              />
            </label>
          </div>
        </div>

        <div className='mt-1 ml-2 h-3 w-full text-3xs text-red-002 text-left truncate'>
          {err2s(error)}
        </div>

        <button
          type='submit'
          className={`mt-1 w-full btn-primary h-12.5 flex items-center justify-center`}
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting && (
            <Loading text='' containerClassName='mr-2' />
          )}
          Continue
        </button>
      </form>
    </OnboardingPageLayout>
  );
}

export function Component() {
  const organizer = useMyOrganizer();
  const updateOrganizer = useUpdateMyOrganizer();

  if (!organizer || organizer.activated) {
    return (
      <Navigate
        to={{
          pathname: $path('/onboarding/headcount'),
          search: window.location.search,
        }}
        replace
      />
    );
  }
  return <Container organizer={organizer} onChange={updateOrganizer} />;
}
